<template>
  <div>
    <!-- UPLOAD -->
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("IMPORT.IMPORT") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form">
        <div class="card-body mx-4">
          <!-- DATE -->
          <div class="row" data-app>
            <v-menu v-model="showMenu" max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <b-input-group :prepend="$t('IMPORT.DATE')" class="mb-3 p-0">
                  <b-form-input
                    readonly
                    :value="date"
                    v-bind="attrs"
                    v-on="on"
                  ></b-form-input>
                </b-input-group>
              </template>
              <v-date-picker
                type="month"
                v-model="date"
                no-title
                color="#3699FF"
              ></v-date-picker>
            </v-menu>
          </div>
          <!-- UPLOAD FILE -->
          <div class="row">
            <b-form-file
              v-model="file"
              :placeholder="$t('IMPORT.UPLOAD_PLACEHOLDER')"
              :drop-placeholder="$t('IMPORT.UPLOAD_DROP')"
            ></b-form-file>
          </div>
          <div class="row">
            <button @click="uploadFiles" class="btn btn-primary btn-block mt-4">
              {{ $t("IMPORT.UPLOAD") }}
            </button>
          </div>
        </div>
      </form>
      <!--end::Card Body-->
    </div>

    <!-- RESULTS -->
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("IMPORT.IMPORT") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form">
        <div class="card-body mx-4">
          <div class="text-center" v-if="inProgress">
            <v-progress-circular
              indeterminate
              color="#3699FF"
              size="50"
            ></v-progress-circular>
          </div>
          <div v-else>
            <!-- <button class="btn btn-primary" @click="undoLastImport">
              {{ $t("IMPORT.UNDO") }}
            </button> -->
            <v-data-table
              :headers="headers"
              :items="importedItems"
              class="elevation-1"
              show-expand
              :single-expand="false"
              :server-items-length="nrImport"
              :options.sync="optionsImport"
              loading-text="Loading... Please wait"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <p>
                    {{ $t("IMPORT.ADDED_COMPANIES") }} -
                    {{ item.added_companies }}
                  </p>
                  <p>
                    {{ $t("IMPORT.MODIFIED_COMPANIES") }} -
                    {{ item.modified_companies }}
                  </p>
                </td>
              </template>
              <template v-slot:item.file="{ item }">
                <a href="#" @click="downloadFile(item)">
                  {{ item.file }}
                </a>
              </template>
              <template v-slot:item.status="{ item }">
                {{ getStatus(item.status) }}
              </template>
            </v-data-table>
          </div>
        </div>
      </form>
      <!--end::Card Body-->
    </div>
  </div>
</template>

<script>
import EnergyService from "@/core/services/energy.service";

export default {
  name: "ImportEnergy",
  components: {},
  data() {
    return {
      file: null,
      inProgress: false,
      newClients: [],
      nrImport: 10,
      totalImported: 0,
      totalNewClients: 0,
      date: "",
      showMenu: false,
      headers: [],
      importedItems: [],
      optionsImport: {},
    };
  },
  watch: {
    optionsImport: {
      async handler() {
        this.importedItems = await EnergyService.getImportData("energy");
      },
      deep: true,
    },
  },
  methods: {
    getStatus(status) {
      if (status == 0) {
        return this.$t("IMPORT.PENDING");
      }
      if (status == 1) {
        return this.$t("IMPORT.PROGRESS");
      }
      if (status == 2) {
        return this.$t("IMPORT.COMPLETED");
      }
      if (status == 3) {
        return this.$t("IMPORT.ERROR");
      }
    },
    downloadFile(item) {
      EnergyService.downloadFile({ type: "energy", id: item.id });
    },
    async uploadFiles() {
      if (this.file) {
        this.inProgress = true;

        let formData = new FormData();
        formData.append("file", this.file, this.file.name);
        formData.append("date", this.date);
        formData.append("type", "energy");

        let result = await EnergyService.uploadFile("energy", formData);

        if (result) {
          this.inProgress = false;
          this.newClients = result.items;
          this.totalNewClients = result.new;
          this.totalImported = result.imported;
        }
      }
    },
    async undoLastImport() {
      await EnergyService.undoImport("energy", this.importedItems[0].id);
      this.importedItems = await EnergyService.getImportData("energy");
    },
  },
  async mounted() {
    this.headers = [
      { text: this.$t("IMPORT.FILE"), value: "filename", sortable: false },
      { text: this.$t("IMPORT.STATUS"), value: "status", sortable: false },
      { text: this.$t("IMPORT.DATE"), value: "date", sortable: false },
      {
        text: this.$t("IMPORT.UPLOAD_TIME"),
        value: "upload_time",
        sortable: false,
      },
      { text: this.$t("IMPORT.USER"), value: "user", sortable: false },
    ];

    this.importedItems = await EnergyService.getImportData("energy");
  },
};
</script>
